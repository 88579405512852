<template>
  <div class="contact_us">
    <m-nav :navigationBar="false" ref="nav" :page="'ContactUs'"></m-nav>
    <div class="contact_con">
      <div class="content_t f_16"><h2 class="f_16">Contact</h2></div>
      <div class="content_c">
        <div class="content_c_t">
          <span class="titleIcon"></span>
          <h3>Contact info</h3>
        </div>
        <p><strong>Webnovelsite Free webnovel</strong></p>
        <p><strong>Email: </strong><span>contactus@webnovelsite.com</span></p>
        <p>
          <strong>Telegram group: </strong>
          <span>https://t.me/freewebnovel</span>
        </p>
      </div>
    </div>
    <new-footer-m></new-footer-m>
  </div>
</template>

<script>
import MNav from "@/components/M/MNav.vue";
import NewFooterM from "@/components/M/NewFooterM.vue";
export default {
  components: { MNav, NewFooterM },
  name: "ContactUs",
  metaInfo: {
    title: "Contact-FreeNovel",
    meta: [
      {
        name: "title",
        content: "Contact-FreeNovel",
      },
      {
        name: "description",
        content:
          "Webnovelsite is the foremost English  publisher of Chinese, Korean, Japanese and  English  webnovels and light novels. All thousands of novels are free and updated daily.",
      },
      {
        name: "keywords",
        content:
          "best webnovel, free webnovels, free books, novel updates, webnovel site, best light novels, webnovelsite",
      },
    ],
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.contact_us {
  font-size: 14px;

  .contact_con {
    .content_t {
      cursor: default;
      height: 40px;
      text-transform: uppercase;
      position: relative;
      font-size: 18px;
      border-bottom: 1px solid #ccc;

      h2 {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #4e4e4e;
      }
    }

    .content_c {
      padding: 10px;
      color: #4e4e4e;
      p {
        line-height: 18px;
      }
      .content_c_t {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        line-height: 30px;
        color: #333;
        position: relative;
        font-size: 16px;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: " ";
          height: 1px;
          width: 400px;
          background: #e5e5e5;
        }
        .titleIcon {
          position: relative;
          margin-right: 20px;
          &:after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/contact.png") center center no-repeat;
            background-size: 100% 100%;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
</style>