<template>
  <div class="contact_us" @click.stop="closeSelect">
    <new-nav
      :navigationBar="false"
      ref="nav"
      :page="'ContactUs'"
      :typeList="typeList"
    >
    </new-nav>
    <div class="container content">
      <div class="content_t f_20"><h2>CONTACT</h2></div>
      <div class="content_c">
        <div class="content_c_t f_20">
          <span class="titleIcon"></span>
          <h3>Contact info</h3>
        </div>
        <p><strong>Webnovelsite Free webnovel</strong></p>
        <p><strong>Email: </strong><span>contactus@webnovelsite.com</span></p>
        <p>
          <strong>Telegram group: </strong>
          <span>https://t.me/freewebnovel</span>
        </p>
      </div>
    </div>
    <new-footer></new-footer>
  </div>
</template>

<script>
import { fictionLabel } from "@/api/index.js";
import NewNav from "@/components/pc/NewNav.vue";
import NewFooter from "@/components/pc/NewFooter.vue";
export default {
  components: { NewNav, NewFooter },
  name: "ContactUs",
  metaInfo: {
    title: "Contact-FreeNovel",
    meta: [
      {
        name: "title",
        content: "Contact-FreeNovel",
      },
      {
        name: "description",
        content:
          "Webnovelsite is the foremost English  publisher of Chinese, Korean, Japanese and  English  webnovels and light novels. All thousands of novels are free and updated daily.",
      },
      {
        name: "keywords",
        content:
          "best webnovel, free webnovels, free books, novel updates, webnovel site, best light novels, webnovelsite",
      },
    ],
  },
  data() {
    return {
      typeList: [], //小说类型
    };
  },
  created() {
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.typeList = typeList;
    }
  },
  methods: {
    // 关闭选择框
    closeSelect() {
      this.$refs.nav.clearSelect();
    },
    // 小说类型
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          this.typeList = res.data;
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact_us {
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    flex-grow: 1;
    .content_t {
      cursor: default;
      height: 40px;
      text-transform: uppercase;
      position: relative;
      border-bottom: 1px solid #ccc;
      h2 {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #4e4e4e;
      }
    }
    .content_c {
      padding: 15px;
      color: #4e4e4e;
      font-size: 16px;
      .content_c_t {
        width: 100%;
        margin-bottom: 20px;
        line-height: 30px;
        color: #333;
        position: relative;
        display: flex;
        align-items: center;
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: " ";
          height: 1px;
          width: 400px;
          background: #e5e5e5;
        }
        .titleIcon {
          position: relative;
          margin-right: 20px;
          &:after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/contact.png") center center no-repeat;
            background-size: 100% 100%;
            width: 18px;
            height: 18px;
          }
        }
      }
      p {
        line-height: 24px;
      }
    }
  }
}
</style>